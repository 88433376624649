<template>
  <v-main>
    <v-container>
      <v-container style="height: 2vh"></v-container>
      <v-row justify="center" class="my-5">
        <v-avatar size="164">
          <img
            v-if="avatarUrl != null"
            :src="avatarUrl"
            alt=""
          >
        </v-avatar>
      </v-row>
      <v-row justify="center" class="my-5">
        <v-card flat color="white" width="500">
          <v-card-title>
            {{ this.accountName }}
          </v-card-title>
          <v-card-text>
            <div>公開用のハンドルネームを設定できます。(LINEに登録されたアカウント名は秘匿されます。)</div>
            <div>入力されたハンドルネームはランキングに表示されます。</div>
            <div>32文字以内で入力してください。</div>
            <div style="margin-top: 20px">
              <v-text-field
                v-model="handleName"
                background-color="white"
                :counter="32"
                label="ハンドルネーム"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="onUpdateClick" :disabled="this.$v.$invalid">更新</v-btn>
            <v-btn text color="pink" @click="handleName=''">クリア</v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <v-row justify="center">
        <v-btn
          color="red lighten-2"
          dark
          @click="showLogOutConfirm = true"
        >
          ログアウト
        </v-btn>
      </v-row>
      <v-row justify="center" class="my-5">
        <log-out-confirm :showLogOutConfirm="showLogOutConfirm" @close-logout-confirm="closeLogOutConfirm" />
      </v-row>
    </v-container>

    <!-- loading -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- alert on snackbar -->
    <v-snackbar
      v-model="showError"
      top
    >
      {{ errorText }}
    </v-snackbar>

  </v-main>
</template>

<script>
import store from "@/store"
import axios from "axios"
import LogOutConfirm from "@/components/LogOutConfirm"
import { validationMixin } from 'vuelidate';
import { maxLength, minLength } from 'vuelidate/lib/validators';


export default {
  name: 'Account',
  mixins: [validationMixin],
  data: () => ({
    loading: false,
    showLogOutConfirm: false,
    handleName: "",
    showError: false,
    errorText: ""
  }),
  computed: {
    avatarUrl() {
      if (store.state.auth.lineUser != null) {
        return store.state.auth.lineUser.picture
      } else {
        return null
      }
    },
    accountName() {
      if (store.state.auth.lineUser != null) {
        return store.state.auth.lineUser.name
      } else {
        return null
      }
    },
    handleNameErrors () {
      const errors = []
      if (!this.$v.handleName.$dirty) return errors
      !this.$v.handleName.maxLength && errors.push("32文字以内で入力してください。")
      !this.$v.handleName.minLength && errors.push("1文字以上で入力してください。")
      return errors
    },
  },
  validations: {
    handleName: { maxLength: maxLength(32), minLength: minLength(1) },
  },
  components: {
    LogOutConfirm
  },
  created() {
    this.getHandleName()
  },
  methods: {
    closeLogOutConfirm() {
      this.showLogOutConfirm = false
    },
    getHandleName() {
      this.loading = true
      var lineUserId = ""
      var token = ""
      var url = process.env.VUE_APP_API_ORIGIN + "/api/account/get?account_id=" + lineUserId
      if (process.env.VUE_APP_DEPLOY_MODE == "PROD") {
        lineUserId = store.state.auth.lineUser.sub
        token = store.state.auth.lineAccessToken
      } else {
        lineUserId = "dev"
      }
      url = process.env.VUE_APP_API_ORIGIN + "/api/account/get?account_id=" + lineUserId
      const headers = {
        'content-type': 'application/json',
        'x-line-access-token': token
      }
      axios({
        method: "GET",
        url: url,
        withCredentials: true,
        headers: headers,
        timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
      })
      .then(res => {
        this.handleName = res.data.handle_name
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        if (error.response) {
          if (error.response.status == 403) {
            this.$store.dispatch('logout')
          } else {
            this.errorText = error.response.data.detail
            this.showError = true
          }
        } else {
          this.errorText = "ネットワーク接続を確認してください"
          this.showError = true
        }
      });
    },
    onUpdateClick() {
      this.loading = true
      var lineUserId = ""
      var token = ""
      var url = process.env.VUE_APP_API_ORIGIN + "/api/account/update?account_id=" + lineUserId
      if (process.env.VUE_APP_DEPLOY_MODE == "PROD") {
        lineUserId = store.state.auth.lineUser.sub
        token = store.state.auth.lineAccessToken
      } else {
        lineUserId = "dev"
      }
      url = process.env.VUE_APP_API_ORIGIN + "/api/account/update?account_id=" + lineUserId
      const headers = {
        'content-type': 'application/json',
        'x-line-access-token': token
      }
      axios({
        method: "PUT",
        url: url,
        withCredentials: true,
        headers: headers,
        data: {handle_name: this.handleName},
        timeout: parseInt(process.env.VUE_APP_API_TIMEOUT_MILLISEC)
      })
      .then(res => {
        this.loading = false
        return res
      })
      .catch(error => {
        this.loading = false
        if (error.response) {
          if (error.response.status == 403) {
            this.$store.dispatch('logout')
          } else {
            this.errorText = error.response.data.detail
            this.showError = true
          }
        } else {
          this.errorText = "ネットワーク接続を確認してください"
          this.showError = true
        }
      });
    }
  }
}
</script>